/** @jsx jsx */
import * as React from 'react';
import { graphql, Link, navigate } from 'gatsby';
import Img from 'gatsby-image';
import { DefaultLayout } from '../components/DefaultLayout';
import { jsx } from 'theme-ui';
import { GatsbyHelmet } from '../utils/helmet';
import { MaxLengthString, useGtag } from '../utils/utils';

interface ProjectTemplateProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
        author: {
          name: string
          url: string
        }
      }
    }
    markdownRemark: {
      html: string
      excerpt: string
      frontmatter: {
        title: string
        category: string
        image: any
        projectTitle: string
        projectDescription: any
        projectDate: any
        projectClient: any
        projectAgency: any
        projectRole: any
        portfolioCategories: any
      }
    }
  }
}

const ProjectTemplate: React.FC<ProjectTemplateProps> = ({ data }) => {
  const project = data.markdownRemark.frontmatter;
  return (
    <DefaultLayout>
      <GatsbyHelmet
        title={project.projectTitle || project.title}
        description={project.projectDescription && MaxLengthString(project.projectDescription, 140)}
      />
      <div sx={ProjectWrapper}>
        <Link sx={{ variant: 'link.breadcrumb' }}
              onClick={() => {
                useGtag(
                  'event',
                  'click',
                  'menu',
                  { name: `button-back-top-project-detail-to-${project.category}` },
                );
              }}
              to={`/${project.category}`}>back</Link>
        <h1 sx={{ color: 'primary' }}>{project.projectTitle || project.title}</h1>
        <ul sx={{
          listStyle: 'none',
          paddingLeft: 0,
          display: 'flex',
          flexDirection: ['column', 'column', 'column', 'row'],
          justifyContent: 'space-between',
          li: {
            fontWeight: 400,
            span: {
              fontWeight: 800,
              display: ['inline', 'inline', 'inline', 'block'],
              marginRight: ['10px', '10px', '10px', '0'],
              color: 'secondary',
            }
          },
        }}>
          {project.projectDate &&
          <li><span>Date:</span> {project.projectDate}</li>
          }
          {project.projectClient &&
          <li><span>Client:</span> {project.projectClient}</li>
          }
          {project.projectAgency &&
          <li><span>Agency:</span> {project.projectAgency}</li>
          }
          {project.projectRole &&
          <li><span>My Role:</span> {project.projectRole}</li>
          }
          {project.portfolioCategories &&
          <li><span>Categories:</span> {project.portfolioCategories.map((category: any, index: any) => {
            if(index === project.portfolioCategories.length - 1) {
              return `${category}`
            }
            return `${category}, `
          })}</li>
          }
        </ul>
        <div sx={ProjectThumbWrapper}>
          <div sx={ImageWrapper}>
            <Img sizes={project.image.childImageSharp.sizes}/>
          </div>
        </div>
        <div sx={{marginBottom: '55px'}}>
          {project.projectDescription}
        </div>
        <div sx={{ color: 'text', maxWidth: 740, marginBottom: '55px' }}
             dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}/>
        <Link sx={{ variant: 'link.heading', textAlign: 'center'}}
              onClick={() => {
                useGtag(
                  'event',
                  'click',
                  'menu',
                  { name: `button-back-bottom-project-detail-to-${project.category}` },
                );
              }}
              to={`/${project.category}`}>back</Link>
      </div>
    </DefaultLayout>
  );
};

export default ProjectTemplate;

export const query = graphql`
  query ProjectTemplateQuery($slug: String!) {
    site {
      siteMetadata {
        title
        description
        author {
          name
          url
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
        category
        image {
          childImageSharp {
            sizes(maxWidth: 1080, quality: 100) {
              ...GatsbyImageSharpSizes_tracedSVG
            }
          }
        }
        projectTitle
        projectDescription
        projectDate
        projectClient
        projectAgency
        projectRole
        portfolioCategories
      }
    }
  }
`;

const ProjectWrapper = {
  marginLeft: ['0', '0', '14rem'],
  width: ['100%', '100%', 'calc(100% - 14rem)'],
  maxWidth: ['100%', '100%','675px'],
};

const ProjectThumbWrapper = {
  backgroundColor: 'background',
  display: 'flex',
  maxWidth: '445px',
  margin: '24px 0 48px',
  minHeight: '100%',
  overflow: 'hidden',
  justifyContent: 'center',
  alignItems: 'center',
  border: (theme: { colors: { primary: any; }; }) => `2px solid ${theme.colors.primary}`,
  '&:hover': {
    border: (theme: { colors: { highlight: any; }; }) => `2px solid ${theme.colors.highlight}`,
    transition: 'all 0.3s ease',
  },
  transition: 'all 0.3s ease',
};

const ImageWrapper = {
  height: '100%',
  width: '100%',
  maxHeight: 'calc(100% - 32px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '16px',
  overflow: 'hidden',
  '& > .gatsby-image-wrapper': {
    width: '100%',
    height: '100%',
  },
};
